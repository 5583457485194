<template>
  <div class="FreedomDancing_container">
    <headerWX class="headerWX"></headerWX>
    <div class="content">
      <!-- 背景 -->
      <img class="bg" src="@/assets/imgs/FreedomDancing/bg.png" alt="" />

      <!-- 提示图 -->
      <div class="tit">
        <img src="@/assets/imgs/FreedomDancing/title.png" alt="" />
      </div>

      <!-- 每日任务 -->
      <div class="everyday">
        <!-- 每日任务上 -->
        <div class="everyday_top">
          <img src="@/assets/imgs/FreedomDancing/everyday_top.png" alt="" />
          <div class="everyday_top_item">
            <p
              v-for="(item, index) in everyday_top"
              :key="index"
              class="tit_span"
            >
              <span> {{ item.descprition[0] }} </span>
              <span> {{ item.processTxt }} </span>
            </p>
          </div>
        </div>
        <!-- 每日任务中 -->
        <div class="everyday_centre" style="margin: 20px auto">
          <img src="@/assets/imgs/FreedomDancing/everyday_centre.png" alt="" />
          <div class="everyday_centre_item">
            <p>
              {{ everyday_centre_bottom.todayData.getNum }}/{{
                everyday_centre_bottom.todayData.totalNum
              }}
            </p>
            <div v-if="everyday_centre_btn">
              <img src="@/assets/imgs/FreedomDancing/everyday_centre_btn.png" />
              <span @click="shuaxin">刷新</span>
            </div>
            <div v-else>
              <img src="@/assets/imgs/FreedomDancing/everyday_centre_btn.png" />
              <span>{{ everyday_centre_num }}</span>
            </div>
          </div>
        </div>
        <!-- 每日任务下 -->
        <div class="everyday_bottom">
          <img src="@/assets/imgs/FreedomDancing/everyday_bottom.png" alt="" />
          <div class="everyday_bottom_item">
            <span
              v-for="(item, index) in everyday_centre_bottom.todayPrizeDataList"
              :key="index"
            >
              {{ item.prizeTxt }}X{{ item.prizeNum }}</span
            >
          </div>
          <div class="everyday_bottom_btn">
            <div>
              <img
                v-if="everyday_centre_bottom.todayPrizeDataList[0].status == 0"
                style="filter: grayscale(1)"
                src="@/assets/imgs/FreedomDancing/everyday_bottom_ok.png"
              />
              <img
                v-else-if="
                  everyday_centre_bottom.todayPrizeDataList[0].status == 1
                "
                @click="everyday(1)"
                src="@/assets/imgs/FreedomDancing/everyday_bottom_ok.png"
              />
              <img
                v-else
                src="@/assets/imgs/FreedomDancing/everyday_bottom_no.png"
              />
            </div>
            <div>
              <img
                v-if="everyday_centre_bottom.todayPrizeDataList[1].status == 0"
                style="filter: grayscale(1)"
                src="@/assets/imgs/FreedomDancing/everyday_bottom_ok.png"
              />
              <img
                v-else-if="
                  everyday_centre_bottom.todayPrizeDataList[1].status == 1
                "
                @click="everyday(2)"
                src="@/assets/imgs/FreedomDancing/everyday_bottom_ok.png"
              />
              <img
                v-else
                src="@/assets/imgs/FreedomDancing/everyday_bottom_no.png"
              />
            </div>
          </div>
        </div>
      </div>

      <!--  完成次数-->
      <div class="number_times">
        <!-- 完成次数上 -->
        <div class="tit">
          <img src="@/assets/imgs/FreedomDancing/number_times_title.png" />
          <div class="item">
            <p>{{ everyday_centre_bottom.totalData.totalNum }}</p>
          </div>
        </div>
        <!-- 完成次数下 -->
        <div class="content">
          <div
            class="item"
            v-for="(item, index) in everyday_centre_bottom.goodsInfoList"
            :key="index"
          >
            <img :src="number_times_item[index].bg" alt="" />
            <div class="item_content">
              <el-tooltip
                class="box-item"
                effect="light"
                :content="item.goodsName"
                placement="bottom"
              >
                <img :src="item.goodsImg" alt=""
              /></el-tooltip>
            </div>

            <div class="item_btn">
              <img
                v-if="item.status == 0"
                style="filter: grayscale(1)"
                src="@/assets/imgs/FreedomDancing/number_times_ok.png"
              />
              <img
                v-else-if="item.status == 1"
                @click="duihuan(item, 1)"
                src="@/assets/imgs/FreedomDancing/number_times_ok.png"
              />
              <img
                v-else-if="item.status == 2"
                src="@/assets/imgs/FreedomDancing/number_times_no.png"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- 进阶奖励advanced -->
      <div class="advanced">
        <!-- 进阶奖励上 -->
        <div class="top">
          <img src="@/assets/imgs/FreedomDancing/advanced_top.png" alt="" />
          <div class="item">
            <div class="it_t">
              <p>每日在高级自由模式下完成一局游戏并且获得至少66万分</p>
            </div>
            <div class="it_b">
              <p>自由勋章 x1</p>
              <img
                v-if="everyday_centre_bottom.todayData.isFinish == 0"
                @click="everyday(4)"
                src="@/assets/imgs/FreedomDancing/number_times_ok.png"
              />
              <img
                v-else-if="everyday_centre_bottom.todayData.isFinish == 1"
                src="@/assets/imgs/FreedomDancing/number_times_no.png"
              />
            </div>
          </div>
        </div>

        <!-- 进阶奖励下 -->
        <div class="botton">
          <img src="@/assets/imgs/FreedomDancing/advanced_botton.png" alt="" />
          <div class="item">
            <div>{{ prize.surplusNum }}</div>
            <div>{{ prize.useNum }}</div>
            <div>{{ prize.totalNum }}</div>
          </div>
        </div>
      </div>
      <!-- 商店 -->
      <div class="shop">
        <!-- 商店头部信息 -->
        <div class="tit">
          <img src="@/assets/imgs/FreedomDancing/shop_tit.png" alt="" />
        </div>
        <!-- 商店商品 -->
        <div class="items">
          <div class="item" v-for="(item, index) in shop" :key="index">
            <img src="@/assets/imgs/FreedomDancing/shop_item.png" alt="" />
            <div class="describe">
              <p>
                <span>x{{ item.goodsPrice }}</span>
              </p>
              <div>
                <el-tooltip
                  class="box-item"
                  effect="light"
                  :content="item.goodsName"
                  placement="bottom"
                  ><img :src="item.goodsImg" alt=""
                /></el-tooltip>
              </div>
            </div>
            <div class="btn">
              <img
                v-if="item.status == 0"
                style="filter: grayscale(1)"
                src="@/assets/imgs/FreedomDancing/shop_ok.png"
              />
              <img
                v-else-if="item.status == 1"
                @click="goods(item)"
                src="@/assets/imgs/FreedomDancing/shop_ok.png"
              />
              <img
                v-else-if="item.status == 2"
                src="@/assets/imgs/FreedomDancing/shop_no.png"
              />
              <p>({{ item.buyCount }}/{{ item.limitCount }})</p>
            </div>
          </div>
        </div>
      </div>

      <!-- 活动说明 -->
      <div class="explain">
        <img
          style="margin-top: 20px"
          src="@/assets/imgs/FreedomDancing/explain_tit.png"
          alt=""
        />
        <div class="item">
          <p>1.活动时间2024年8月22日—2024年9月15日24点</p>
          <p>2.活动道具领取/兑换成功后请登录游戏通过纸条箱领取。</p>
          <p>
            3.完成每日任务后请于当日点击刷新保存任务进度，否则无法激活相应活动奖励，并且不计入活动累计次数，逾期不补。
          </p>
          <p>4.活动奖励请及时领取，逾期不补。</p>
        </div>
      </div>
      <!-- over -->

      <!-- 二次确认弹窗 -->
      <div id="mask" v-if="ispopUp">
        <div
          style="
            position: fixed;
            top: 13%;
            left: 35%;
            border-radius: 10px;
            width: 30vw;
            height: 70vh;
            min-width: 600px;
            padding: 5px;
            background: linear-gradient(#d7b7ff, #f9f8ff);
            z-index: 9999;
          "
        >
          <PopUp
            @ispopUp="TzpopUp"
            propShow="3"
            :DBF_goShop="DBF_goShop"
          ></PopUp>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headerWX from "@/components/Header";
import axios from "axios";
import Cookies from "js-cookie";
import PopUp from "@/components/pop_up";

export default {
  name: "FreedomDancing",
  components: {
    headerWX,
    PopUp,
  },
  data() {
    return {
      number_times_item: [
        { bg: require("@/assets/imgs/FreedomDancing/number_times_item1.png") },
        { bg: require("@/assets/imgs/FreedomDancing/number_times_item2.png") },
        { bg: require("@/assets/imgs/FreedomDancing/number_times_item3.png") },
        { bg: require("@/assets/imgs/FreedomDancing/number_times_item4.png") },
        { bg: require("@/assets/imgs/FreedomDancing/number_times_item5.png") },
        { bg: require("@/assets/imgs/FreedomDancing/number_times_item6.png") },
      ],
      everyday_centre_btn: true,
      everyday_centre_num: 60,
      ispopUp: false,
      DBF_goShop: "",
      // 每日任务上
      everyday_top: [],
      // 每日任务中下
      everyday_centre_bottom: {
        todayData: { getNum: 0, isFinish: 0, totalNum: 4 },
        todayPrizeDataList: [],
      },
      //   商品
      shop: {},
      //   prize奖品
      prize: [],
    };
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    // 初始化
    async init() {
      await axios({
        method: "post",
        url: "/activity/getActivityList",
        headers: {
          token: Cookies.get("wx_token"),
        },
        data: {
          activityName: "2024free_activity",
          userId: Cookies.get("wx_userId"), // userId
          platForm: "web",
        },
      }).then((res) => {
        if (res.data.state === 200) {
          this.everyday_top = res.data.data.dayActivityConfigList;
        }
      });
      await axios({
        method: "post",
        url: "/activity/getActivityData",
        headers: {
          token: Cookies.get("wx_token"),
        },
        data: {
          activityName: "2024free_activity",
          userId: Cookies.get("wx_userId"), // userId
          platForm: "web",
        },
      }).then((res) => {
        if (res.data.state === 200) {
          this.everyday_centre_bottom = res.data.data;
        }
      });
      await axios({
        method: "post",
        url: "/activity/getActivityData",
        headers: {
          token: Cookies.get("wx_token"),
        },
        data: {
          activityName: "2024medal_activity",
          userId: Cookies.get("wx_userId"), // userId
          platForm: "web",
        },
      }).then((res) => {
        if (res.data.state === 200) {
          this.shop = res.data.data.goodsInfoList;
          this.prize = res.data.data.totalData;
        }
      });
    },

    // 刷新
    shuaxin() {
      this.everyday_centre_btn = false;
      this.init();
      //   this.everyday(3);
      let timer = setInterval(() => {
        if (this.everyday_centre_num === 0) {
          clearInterval(timer);
          this.everyday_centre_num = 60;
          this.everyday_centre_btn = true;
        } else {
          this.everyday_centre_num--;
        }
      }, 1000);
    },

    // 领取奖励
    async everyday(index) {
      await axios({
        method: "post",
        url: "/activity/getActivityDayPrize",
        headers: {
          token: Cookies.get("wx_token"),
        },
        data: {
          activityName: "2024free_activity",
          userId: Cookies.get("wx_userId"), // userId
          platForm: "web",
          prizeId: index,
        },
      }).then((res) => {
        if (res.data.state === 200) {
          this.init();
          this.$message.success(res.data.message);
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    // 商品领取
    async duihuan(item) {
      await axios({
        method: "post",
        url: "/activity/buyActivityGoods",
        headers: {
          token: Cookies.get("wx_token"),
        },
        data: {
          //   activityName: index == 1 ? "2024free_activity" : "2024medal_activity",
          activityName: "2024free_activity",
          userId: Cookies.get("wx_userId"), // userId
          platForm: "web",
          goodsId: item.goodsId,
          goodsNum: 1,
        },
      }).then((res) => {
        if (res.data.state === 200) {
          this.init();
          this.$message.success(res.data.message);
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    // 商品购买
    goods(item) {
    //   console.log("弹窗", item);
      // 赋值要兑换的商品信息
      this.DBF_goShop = item;

      // 弹窗打开
      this.ispopUp = true;
    },
    // 关闭蒙层
   async TzpopUp(msg) {
      if (msg.data == true && msg.type == 3) {
        // console.log("关闭弹窗", this.DBF_goShop);
        // this.shopBuy(this.DBF_goShop)
       await axios({
          method: "post",
          url: "/activity/buyActivityGoods",
          headers: {
            token: Cookies.get("wx_token"),
          },
          data: {
            activityName: "2024medal_activity",
            goodsId: this.DBF_goShop.goodsId,
            goodsNum: 1,
            userId: Cookies.get("wx_userId"), // userId
            platForm: "web",
          },
        })
          .then((res) => {
            if (res.data.state == 200) {
              // console.log(res.data);
              this.init();
              this.$message.success(res.data.message);

            } else {
              this.$message.error(res.data.message);
            }
          })
          .catch((err) => {
            this.$message.error(err.message);
          });
      } else if (msg.data == false && msg.type == 3) {
        // this.$message.info("已取消");
      }
      this.ispopUp = false;
    },
  },
};
</script>

<style lang="less" scoped>
.FreedomDancing_container {
  min-width: 1200px;

  > .content {
    margin: 0 auto;
    max-width: 2000px;
    width: 100%;
    position: relative;
    top: -100px;

    .bg {
      width: 100%;
      position: absolute;
      // top: 0;
      z-index: -1;
    }
    > .tit {
      padding-top: 90px;
      text-align: center;
    }
    // 每日任务
    .everyday {
      > div {
        margin: auto;
        position: relative;
        width: fit-content;
        // 每日任务上
        .everyday_top_item {
          width: 94%;
          position: absolute;
          display: flex;
          bottom: 15%;
          left: 50%;
          transform: translateX(-50%);
          p {
            flex: 1;
            text-align: center;

            span {
              width: 80%;
              display: inline-block;
              font-size: 22px;
              font-weight: 700;
              line-height: 40px;
              color: #8112ff;
            }
          }
        }
        // 每日任务中
        .everyday_centre_item {
          position: absolute;
          right: 15%;
          top: 45%;
          display: flex;

          p {
            line-height: 34px;
            font-size: 30px;
            font-weight: 700;
            color: #8112ff;
            margin-right: 20px;
          }
          > div {
            position: relative;
            span {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-size: 20px;
              font-weight: 700;
              color: #8112ff;
              cursor: pointer;
            }
          }
        }
        // 每日任务下

        .everyday_bottom_item {
          position: absolute;
          width: 100%;
          display: flex;
          bottom: 40%;
          left: 50%;
          transform: translateX(-50%);
          span {
            flex: 1;
            text-align: center;
            font-size: 30px;
            font-weight: 700;
            color: #8112ff;
          }
        }
        .everyday_bottom_btn {
          display: flex;
          > div {
            flex: 1;
            text-align: center;
          }
        }
      }
    }
    // 完成次数
    .number_times {
      > div {
        margin: auto;
        position: relative;
        width: fit-content;
      }
      > .tit {
        margin-bottom: 20px;
        .item {
          position: absolute;
          //   display: flex;
          top: 50%;
          right: 15%;
          transform: translateY(-50%);
          font-size: 30px;
          font-weight: 700;
          color: #8112ff;
          p {
            font-size: 30px;
            font-weight: 700;
            color: #8112ff;
            margin-right: 20px;
          }
          //   > div {
          //     position: relative;
          //     span {
          //       position: absolute;
          //       top: 50%;
          //       left: 50%;
          //       transform: translate(-50%, -50%);
          //       font-size: 20px;
          //       font-weight: 700;
          //       color: #8112ff;
          //       cursor: pointer;
          //     }
          //   }
        }
      }
      > .content {
        width: 40%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .item {
          position: relative;
          margin-bottom: 20px;
          .item_content {
            width: 150px;
            position: absolute;
            top: 43%;
            left: 50%;
            transform: translate(-50%, -50%);
            img {
              width: 100%;
            }
          }
          .item_btn {
            text-align: center;
          }
        }
      }
    }

    // 进阶奖励
    .advanced {
      margin-top: 40px;
      > div {
        margin: auto;
        width: fit-content;
      }
      .top {
        position: relative;
        .item {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 85%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #8112ff;
          > div {
            flex: 1;
          }
          .it_t {
            p {
              width: 80%;
              font-size: 26px;
            }
          }
          .it_b {
            display: flex;
            justify-content: center;
            align-items: center;
            p {
              font-size: 26px;
              padding: 20px;
            }
          }
        }
      }
      .botton {
        position: relative;
        .item {
          position: absolute;
          top: 55%;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          justify-content: center;
          align-items: center;
          width: 95%;
          > div {
            flex: 1;
            text-align: center;
            color: #8112ff;
            font-size: 32px;
            font-weight: 800;
          }
        }
      }
    }

    // 商店
    .shop {
      > .tit {
        width: fit-content;
        margin: auto;
        margin-top: 20px;
      }
      .items {
        width: 50%;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        .item {
          position: relative;
          margin-bottom: 40px;
          .describe {
            position: absolute;
            top: 8px;
            width: 100%;
            height: 180px;
            display: flex;
            flex-direction: column;
            align-items: center;
            p:nth-child(1) {
              width: 100%;
              height: 80px;
              line-height: 95px;
              span {
                color: #8112ff;
                text-align: right;
                font-size: 28px;
                float: right;
                width: 52%;
                text-align: left;
              }
            }
          }
          .btn {
            width: fit-content;
            margin: auto;
            p {
              text-align: center;
              font-weight: 800;
            }
          }
        }
      }
    }

    // 活动说明
    .explain {
      position: absolute;
      bottom: -500px;
      left: 50%;
      transform: translateX(-50%);
      width: 50%;
      height: 400px;
      background: rgba(255, 255, 255, 0.4);
      border-radius: 10px 10px 10px 10px;
      border: 1px solid #ffffff;
      text-align: center;
      > .item {
        text-align: left;
        margin-top: 30px;
        margin-left: 30px;
        p {
          color: #ffffff;
          font-size: 26px;
          margin-bottom: 20px;
          font-weight: 900;
          line-height: 40px;
          -webkit-text-stroke: 1px #8112ff;
        }
      }
    }
  }
}
</style>
